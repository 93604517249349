import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { RunsheetReadDto, SearchResultDocuments } from 'types/dto/runsheetDto';

export interface runsheetState {
    runsheetDetails: RunsheetReadDto;
}

export const initialState: runsheetState = {
    runsheetDetails: null
};

const runsheetSlice = createSlice({
    name: 'examOrderManualSearch',
    initialState,
    reducers: {
        /**
         * Set the runsheet details
         * @param state Slice state
         * @param action Payload with the RunsheetReadDto object
         */
        setRunsheetDetails(state, action: PayloadAction<RunsheetReadDto>) {
            state.runsheetDetails = action.payload;
        },
        setRunsheetDocuments(state, action: PayloadAction<SearchResultDocuments[]>) {
            const updatedDocuments = action.payload;

            if (state?.runsheetDetails?.searchResultDocuments) {
                state.runsheetDetails.searchResultDocuments =
                    state.runsheetDetails.searchResultDocuments.map((existingDoc) => {
                        const updatedDoc = updatedDocuments.find(
                            (doc) => doc.id === existingDoc.id
                        );
                        return updatedDoc ? { ...existingDoc, ...updatedDoc } : existingDoc;
                    });
            }
        }
    }
});

export const { setRunsheetDetails, setRunsheetDocuments } = runsheetSlice.actions;

export const getInteractiveRunsheet =
    (groupId: string, documentId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderSearchPackageGroupData, examOrderKeyDocumentGroupData } =
                getState();
            const selectedGroup = documentId
                ? examOrderSearchPackageGroupData.examOrderSearchPackageGroup
                : examOrderKeyDocumentGroupData.examOrderDocumentGroup;

            const {
                isAtsFullSearch,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId
            } = documentId
                ? selectedGroup
                      .find((group) => group.groupId === groupId)
                      .documents.find((document) => document.id === documentId)
                : selectedGroup.find((group) => group.groupId === groupId);
            const { id: orderId } = getState().currentExamOrderData.currentExamOrder;
            const results = await api.runsheet.getInteractiveRunsheet({
                isAtsFullSearch,
                orderId,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId
            });
            if (documentId) {
                results.searchPackageId = documentId;
            } else {
                results.keyDocumentsId = groupId;
            }
            dispatch(setRunsheetDetails(results));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get Interactive Runsheet: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const getPdfRunsheet =
    (groupId: string, documentId: string): AppThunk =>
    async (
        dispatch,
        getState
    ): Promise<{ filename: string; buffer: ArrayBuffer; type: string } | undefined> => {
        try {
            const {
                isAtsFullSearch,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId
            } = getState()
                .examOrderSearchPackageGroupData.examOrderSearchPackageGroup.find(
                    (group) => group.groupId === groupId
                )
                .documents.find((document) => document.id === documentId);
            const { id: orderId } = getState().currentExamOrderData.currentExamOrder;
            const res = await api.runsheet.getPdfRunsheet({
                isAtsFullSearch,
                orderId,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId
            });
            return { filename: 'runsheet', buffer: res.buffer, type: res.type };
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get PDF Runsheet: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
            return undefined;
        }
    };

export const saveInteractiveRunsheet =
    (
        groupId: string,
        documentId: string,
        documents: {
            id: string;
            searchResultId: string;
            searchResultDocId: string;
            dataProviderUniqueDocId: string;
            viewImageActionUrl: string;
            isFinalDocument: boolean;
            isTagged: boolean;
            isSaved: boolean;
        }[]
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderSearchPackageGroupData, examOrderKeyDocumentGroupData } =
                getState();
            const selectedGroup = documentId
                ? examOrderSearchPackageGroupData.examOrderSearchPackageGroup
                : examOrderKeyDocumentGroupData.examOrderDocumentGroup;

            const {
                isAtsFullSearch,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId
            } = documentId
                ? selectedGroup
                      .find((group) => group.groupId === groupId)
                      .documents.find((document) => document.id === documentId)
                : selectedGroup.find((group) => group.groupId === groupId);
            const { id: orderId } = getState().currentExamOrderData.currentExamOrder;
            const results = await api.runsheet.saveRunsheet({
                isAtsFullSearch,
                orderId,
                pulseSearchId,
                dataSourceSearchId,
                searchRequestId,
                dataProviderActorId,
                propertyId,
                documents
            });
            dispatch(setRunsheetDocuments(results.searchResultDocuments));
            if (results.isSuccess) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: results.message,
                        severity: SnackbarSeverity.Success
                    })
                );
            }
        } catch (err) {
            if (err && err.response === undefined) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `The file save process is taking longer than expected. The process will be completed shortly. Please check again after some time.`,
                        severity: SnackbarSeverity.Info
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Save Runsheet: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

export default runsheetSlice.reducer;
