import { AuthenticatedTemplate } from '@azure/msal-react';
import LoadingModal from 'components/common/LoadingModal';
import MsalInstanceProvider from 'components/common/MsalInstanceProvider';
import api, { useAxiosLoader } from 'core/api/api';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
/**
 * Component for downloading external fule
 * @component
 * @returns {JSX.Element}
 */
const DownloadExternalFile = () => {
    const { path } = useParams();
    const [loading] = useAxiosLoader(true);
    useEffect(() => {
        (async () => {
            window.addEventListener('beforeunload', () => {
                window.opener?.postMessage('windowClosed');
            });
            const endpoint = `/api/manualSearch/downloadAtsDocument/${path}`;
            const response = await api.get<ArrayBuffer>(endpoint, {
                responseType: 'arraybuffer'
            });
            const blob = new Blob([response?.data], {
                type: response.headers['Content-Type']?.toString() ?? 'application/pdf'
            });
            const blobURL = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.src = blobURL;
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.style.border = 'none';
            iframe.style.position = 'absolute';
            document.body.innerHTML = iframe.outerHTML;
        })();
    }, []);
    return (
        <div>
            <MsalInstanceProvider>
                <>
                    <AuthenticatedTemplate>
                        <LoadingModal isOpen={loading} cancelHandler={null} />
                    </AuthenticatedTemplate>
                </>
            </MsalInstanceProvider>
        </div>
    );
};
export default DownloadExternalFile;