import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    SnackbarSeverity,
    WorkbenchTabsEnum,
    OrderReviewStateTypes,
    WorkbenchOrderDetailsSubTabsEnum
} from 'core/constants/common';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { setExamCodeBookTemplatesData } from 'core/features/examCodeBook/examCodeBookSlice';
import { ExaminerReviewState, UpdateSectionStatus } from 'types/dataModels';

const initialState = {
    tabs: {
        [WorkbenchTabsEnum.searchReport]: {
            reviewState: OrderReviewStateTypes.bell,
            isExpanded: true,
            errorState: false
        },
        [WorkbenchTabsEnum.orderDetails]: {
            reviewState: OrderReviewStateTypes.alert,
            isExpanded: false,
            errorState: false,
            subTabs: {
                [WorkbenchOrderDetailsSubTabsEnum.generalInfo]: {
                    reviewState: OrderReviewStateTypes.alert,
                    errorState: false
                },
                [WorkbenchOrderDetailsSubTabsEnum.propertyDetail]: {
                    reviewState: OrderReviewStateTypes.alert,
                    errorState: false
                },
                [WorkbenchOrderDetailsSubTabsEnum.parties]: {
                    reviewState: OrderReviewStateTypes.alert,
                    errorState: false
                }
            }
        },
        [WorkbenchTabsEnum.vestingOwners]: {
            reviewState: OrderReviewStateTypes.alert,
            isExpanded: false,
            errorState: false
        },
        [WorkbenchTabsEnum.legalDescription]: {
            reviewState: OrderReviewStateTypes.alert,
            isExpanded: false,
            errorState: false
        },
        [WorkbenchTabsEnum.taxes]: {
            reviewState: OrderReviewStateTypes.alert,
            isExpanded: false,
            errorState: false
        },
        [WorkbenchTabsEnum.exceptionsRequirements]: {
            reviewState: OrderReviewStateTypes.alert,
            isExpanded: false,
            errorState: false
        }
    },
    activeTab: WorkbenchTabsEnum.searchReport,
    isReadyForFinalReview: false,
    isFinalReviewVisible: false,
    isPopoutFinalReview: false,
    isCompleteReviewButtonDisabled: false,
    openTaxes: [] as string[]
};

const workbenchTabsSlice = createSlice({
    name: 'workbenchTabs',
    initialState,
    reducers: {
        /**
         * Set the current tab in Workbench
         * @param state Slice state
         * @param action Payload with the enum value of the currently selected tab
         */
        setCurrentWorkbenchTab(state, action: PayloadAction<WorkbenchTabsEnum>) {
            state.activeTab = action.payload;
        },
        /**
         * Set the expanded state of a Workbench tab
         * @param state Slice state
         * @param action Payload with the name of the tab and isExpanded state
         */
        setWorkbenchTabExpandedState(
            state,
            action: PayloadAction<{ tabName: WorkbenchTabsEnum; isExpanded: boolean }>
        ) {
            const { tabName, isExpanded } = action.payload;
            state.tabs[tabName].isExpanded = isExpanded;
        },
        /**
         * Set the expanded state of a taxes list items
         * @param state Slice state
         * @param action Payload with the ids of open tax items
         */
        setTaxesOpenGroupExpandedState(state, action: PayloadAction<string[]>) {
            state.openTaxes = action.payload;
        },
        /**
         * toggle between tabs/workbench section by clicking on workbench tabs
         * @param state Slice state
         * @param action Payload with the name of the tab and isExpanded state
         */
        setWorkbenchTabsToggleState(
            state,
            action: PayloadAction<{ tabName: WorkbenchTabsEnum; isExpanded: boolean }>
        ) {
            const { tabName, isExpanded } = action.payload;
            Object.values(WorkbenchTabsEnum).forEach((tab) => {
                if (tab === tabName) {
                    state.tabs[tab].isExpanded = isExpanded;
                } else {
                    state.tabs[tab].isExpanded = false;
                }
            });
        },
        /**
         * Set the reviewed state of Workbench tabs
         * @param state Slice state
         * @param action Payload with the reviewed state of Workbench tabs
         */
        setWorkbenchExamOrderReviewState(state, action: PayloadAction<ExaminerReviewState>) {
            state.tabs[WorkbenchTabsEnum.searchReport].reviewState = action.payload
                .isSearchReportReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.orderDetails].reviewState = action.payload
                .isOrderDetailsReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.orderDetails].subTabs[
                WorkbenchOrderDetailsSubTabsEnum.generalInfo
            ].reviewState = action.payload.isOrderDetailsGeneralReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.orderDetails].subTabs[
                WorkbenchOrderDetailsSubTabsEnum.propertyDetail
            ].reviewState = action.payload.isOrderDetailsPropertiesReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.orderDetails].subTabs[
                WorkbenchOrderDetailsSubTabsEnum.parties
            ].reviewState = action.payload.isOrderDetailsPartiesReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.legalDescription].reviewState = action.payload
                .isLegalDescriptionReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.vestingOwners].reviewState = action.payload
                .isVestingReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.taxes].reviewState = action.payload.isTaxesReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.tabs[WorkbenchTabsEnum.exceptionsRequirements].reviewState = action.payload
                .isExceptionsReviewed
                ? OrderReviewStateTypes.completed
                : OrderReviewStateTypes.alert;
            state.isReadyForFinalReview = action.payload.isReadyForFinalReview;
        },
        /**
         * Reset the state of Workbench tabs to their initial value
         * @param state Slice state
         */
        resetWorkbenchTabs(state) {
            state.tabs = initialState.tabs;
            state.activeTab = initialState.activeTab;
        },
        /**
         * Set the visibility of the 'start Final Review' button in Workbench
         * @param state Slice state
         * @param action Payload with the isFinalReviewVisible flag
         */
        setIsFinalReviewVisible(state, action: PayloadAction<boolean>) {
            state.isFinalReviewVisible = action.payload;
        },
        /**
         * Set the popout state of Final Review
         * @param state Slice state
         * @param action Payload with the isFinalReviewVisible flag
         */
        setIsPopoutFinalReview(state, action: PayloadAction<boolean>) {
            state.isPopoutFinalReview = action.payload;
        },
        /**
         * Set the error state of Exceptions and Requirements
         * @param state Slice state
         * @param action Payload with the errorState flag respective to Exceptions and Requirements panel
         */
        setExceptionsRequirementsErrorState(state, action: PayloadAction<boolean>) {
            state.tabs[WorkbenchTabsEnum.exceptionsRequirements].errorState = action.payload;
        },
        /**
         * Set the error state of Order Details Section
         * @param state Slice state
         * @param action Payload with the subTabName and errorState flag respective to Order Details panel
         */
        setOrderDetailsErrorState(
            state,
            action: PayloadAction<{
                subtabName: WorkbenchOrderDetailsSubTabsEnum;
                errorState: boolean;
            }>
        ) {
            const { subtabName, errorState } = action.payload;
            state.tabs[WorkbenchTabsEnum.orderDetails].subTabs[subtabName].errorState =
                errorState;
            const isErrorInOrderDetails = Object.keys(
                state.tabs[WorkbenchTabsEnum.orderDetails].subTabs
            ).some((subTab: WorkbenchOrderDetailsSubTabsEnum) => {
                return state.tabs[WorkbenchTabsEnum.orderDetails].subTabs[subTab].errorState;
            });
            state.tabs[WorkbenchTabsEnum.orderDetails].errorState = isErrorInOrderDetails;
        },
        setCompleteReviewButtonState(state, action: PayloadAction<boolean>) {
            state.isCompleteReviewButtonDisabled = action.payload;
        }
    }
});

export const {
    setCurrentWorkbenchTab,
    setWorkbenchTabExpandedState,
    setTaxesOpenGroupExpandedState,
    setWorkbenchExamOrderReviewState,
    resetWorkbenchTabs,
    setIsFinalReviewVisible,
    setIsPopoutFinalReview,
    setExceptionsRequirementsErrorState,
    setOrderDetailsErrorState,
    setCompleteReviewButtonState,
    setWorkbenchTabsToggleState
} = workbenchTabsSlice.actions;
/**
 * Fetch exam order review state from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrderReviewStateThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderReviewState.getExamOrderReviewState(orderId);
            dispatch(setWorkbenchExamOrderReviewState(response));
        } catch (err) {
            dispatch(setExamCodeBookTemplatesData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order review state: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update section review status
 * @param {WorkbenchTabsEnum} panelName Name of the Workbench panel to update status for
 * @param {UpdateSectionStatus} value New status value
 * @returns {AppThunk}
 */
export const updateSectionReviewStatusThunk =
    (
        panelName: WorkbenchTabsEnum | WorkbenchOrderDetailsSubTabsEnum,
        value: UpdateSectionStatus
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const orderId = getState().currentExamOrderData.currentExamOrder.id;
            let response = {} as ExaminerReviewState;
            switch (panelName) {
                case WorkbenchTabsEnum.searchReport:
                    response =
                        await api.examOrderReviewState.updateSearchReportSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
                case WorkbenchTabsEnum.orderDetails:
                    response =
                        await api.examOrderReviewState.updateOrderDetailsSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
                case WorkbenchOrderDetailsSubTabsEnum.generalInfo:
                    response =
                        await api.examOrderReviewState.updateOrderDetailsGeneralSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
                case WorkbenchOrderDetailsSubTabsEnum.propertyDetail:
                    response =
                        await api.examOrderReviewState.updateOrderDetailsPropertiesSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
                case WorkbenchOrderDetailsSubTabsEnum.parties:
                    response =
                        await api.examOrderReviewState.updateOrderDetailsPartiesSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
                case WorkbenchTabsEnum.taxes:
                    response = await api.examOrderReviewState.updateTaxesSectionReviewStatus(
                        orderId,
                        value
                    );
                    break;
                case WorkbenchTabsEnum.exceptionsRequirements:
                    response =
                        await api.examOrderReviewState.updateExceptionsSectionReviewStatus(
                            orderId,
                            value
                        );
                    break;
            }

            dispatch(setWorkbenchExamOrderReviewState(response));
        } catch (err) {
            dispatch(setExamCodeBookTemplatesData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update workbench panels review state: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default workbenchTabsSlice.reducer;
