import { GTMIDS, MSAL_CONFIG } from 'core/constants/common';
import store from 'core/store/store';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './styles/fonts.scss';
import './styles/index.scss';

const msalConfig = JSON.parse(localStorage.getItem(MSAL_CONFIG));
const gtmId: string = GTMIDS?.[msalConfig?.env || 'Development'];

// else if condition as some browsers dont support requestIdleCallback
if (gtmId && 'requestIdleCallback' in window) {
    requestIdleCallback(() => {
        import('react-ga').then((ReactGA) => {
            ReactGA.initialize(gtmId);
        });
    });
} else if (gtmId) {
    import('react-ga').then((ReactGA) => {
        ReactGA.initialize(gtmId);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
